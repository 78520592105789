import { useStaticQuery, graphql } from "gatsby"

export const useConsultationCardImages = () => {
  const data = useStaticQuery(graphql`
    {
      pendingsubmission: file(
        relativePath: { eq: "icons/pending-submission.png" }
      ) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      incomplete: file(relativePath: { eq: "icons/incomplete.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      confirmed: file(relativePath: { eq: "icons/check.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      submitted: file(relativePath: { eq: "icons/submitted.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}
