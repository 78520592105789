export const columnsMH = [
  {
    name: "Patient ID",
    options: {
      display: false,
    },
  },
  {
    name: "Consult ID",
    options: {
      display: false,
      searchable: true,
    },
  },
  {
    name: "Consult Start Date",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          if (
            new Date(obj1?.data?.data).toString() === "Invalid Date" ||
            new Date(obj2?.data?.data).toString() === "Invalid Date"
          ) {
            return -1
          }

          return (
            (new Date(obj1?.data?.data) - new Date(obj2?.data?.data) > 0
              ? 1
              : -1) * (order === "asc" ? 1 : -1)
          )
        }
      },
    },
    selector: "consultStartDate",
    sortable: true,
  },
  {
    name: "Consult ID",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
    },
  },
  {
    name: "Patient Name",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
    },
    selector: "patientName",
    sortable: true,
  },
  {
    name: "Consult Status",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
    },
  },
  {
    name: "Risk Assessment",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          const getRiskValue = (risk) => {
            switch (risk?.data?.data?.toLowerCase()) {
              case "severe":
              case "high":
                return 10
              case "moderately severe":
                return 9
              case "mild":
                return 7
              case "moderate":
                return 8
              case "normal":
                return 5
              case "low":
                return 2
              case "no psychopathology":
              case "no follow-up session with a psychologist":
              default:
                return 0
            }
          }

          return (
            (getRiskValue(obj1) !== getRiskValue(obj2)
              ? getRiskValue(obj1) - getRiskValue(obj2)
              : obj1?.data?.data
                  ?.toLowerCase()
                  .localeCompare(obj2?.data?.data?.toLowerCase())) *
            (order === "asc" ? 1 : -1)
          )
        }
      },
    },
  },
  {
    name: "Next Steps",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
      searchable: true,
    },
  },
  {
    name: "",
    options: {
      sort: false,
    },
  },
]
