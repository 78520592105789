import moment from "moment"

export const getConsultationByType = ({ consultation }, status) => {
  const DAYS_BUFFER = 7
  let isUpcomingConsult =
    consultation?.consultStatus?.toLowerCase() === "tentative" ||
    consultation?.consultStatus?.toLowerCase() === "confirmed" ||
    (consultation?.consultStatus === undefined &&
      consultation?.isDraft === undefined)

  let isSubmittedConsult =
    (consultation?.isDraft === false &&
      consultation?.consultStatus?.toLowerCase() === "completed") ||
    consultation?.consultStatus?.toLowerCase() === "done"

  let daysPassed = moment
    .duration(moment().diff(moment(consultation?.startDateTime)))
    .asDays()

  switch (status) {
    //should return all

    case "upcoming":
      return isUpcomingConsult && daysPassed < 1

    case "incomplete":
      return (
        (isUpcomingConsult || consultation?.isDraft === true) &&
        daysPassed > 1 &&
        !isSubmittedConsult
      )

    case "submitted":
      return (
        !isUpcomingConsult && isSubmittedConsult && daysPassed < DAYS_BUFFER
      )
    case "completed":
      return (
        !isUpcomingConsult && isSubmittedConsult && daysPassed > DAYS_BUFFER
      )
    default:
      return true
  }
}
