import moment from "moment"

import { isBrowser, truncateString, renderStringValue } from "services/general"
import { getConsultationByType } from "../../../Consultations/services/getConsultationByType.js"

import {
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
  GATSBY_PSYCHIATRIST_ROLE_ID,
} from "gatsby-env-variables"

import { getMHPConsultations } from "./getMHPConsultations.js"

const getConsultDate = (consultation) => {
  return (
    moment(consultation?.startDateTime).format("YYYY-MM-DD hh:mm A") ||
    moment
      .unix(consultation?.consultation?.consultationDateTime, "dateTime")
      .format("YYYY-MM-DD hh:mm A")
  )
}

const getConsultStatusString = (consultation) => {
  let isUpcomingConsult =
    consultation?.consultStatus?.toLowerCase() === "tentative" ||
    consultation?.consultStatus?.toLowerCase() === "confirmed" ||
    (consultation?.consultStatus === undefined &&
      consultation?.isDraft === undefined)

  let isSubmittedConsult =
    (consultation?.isDraft === false &&
      consultation?.consultStatus?.toLowerCase() === "completed") ||
    consultation?.consultStatus?.toLowerCase() === "done"
  switch (true) {
    case consultation?.isDraft === false:
      return "Submitted"
    case consultation?.isDraft === true:
      return "Draft"
    case isUpcomingConsult &&
      moment().diff(moment(consultation?.startDateTime)) > 0:
      return "Incomplete"
    case isUpcomingConsult:
      return "Upcoming"
    case isSubmittedConsult:
      return "Completed"
  }
}

export const getDatatableConsultations = async ({ userData }) => {
  let allConsultations = []

  allConsultations = await getMHPConsultations({ userEnrollment: userData })

  const getConsultStatus = () => {
    if (isBrowser()) {
      let urlParameters = new URLSearchParams(window.location.search)
      if (
        urlParameters?.get("status") === "incomplete" ||
        urlParameters?.get("status") === "submitted" ||
        urlParameters?.get("status") === "upcoming" ||
        urlParameters?.get("status") === "completed"
      )
        return urlParameters?.get("status")
      return null
    }
  }

  const getPatientName = (patientNames, patientId) => {
    let patient = patientNames?.find(
      (patient) => patient?.patientId === patientId
    )

    return patient?.firstName + " " + patient?.lastName
  }

  let datatableConsultations = allConsultations?.filter((consultation) =>
    getConsultationByType({ consultation }, getConsultStatus())
  )

  let patientNames = JSON.parse(sessionStorage.getItem("patients")).map(
    (patient) => {
      return {
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        patientId: patient?.id,
      }
    }
  )

  // get schedule info from allDates session storage for each consultation
  // schedule info is used for date and time column in datatable
  datatableConsultations = datatableConsultations.map((consultation) => {
    let schedule

    if (consultation?.googleCalendarEventId) {
      schedule = JSON.parse(sessionStorage.getItem("allDates")).find(
        (event) => event?.id === consultation.googleCalendarEventId
      )

      return { ...consultation, schedule }
    }

    return consultation
  })

  return datatableConsultations.map((consultation) => {
    switch (userData.role) {
      case GATSBY_MHP_ROLE_ID:
      case GATSBY_PSYCHIATRIST_ROLE_ID:
        return [
          { id: true, type: "string", data: consultation?.userId },
          { id: true, type: "string", data: consultation?.id },
          {
            type: "string",
            data: getConsultDate(consultation) || "N/A",
          },
          { type: "string", data: consultation?.zendeskId || "N/A" },

          {
            type: "string",
            data:
              truncateString(
                getPatientName(patientNames, consultation?.userId),
                25
              ) || "N/A",
          },
          {
            type: "string",
            data: getConsultStatusString(consultation) || "N/A",
          },
          { type: "string", data: consultation?.riskAssessment || "N/A" },
          {
            type: "string",
            data:
              truncateString(
                renderStringValue({
                  value: consultation?.specificRecommendation,
                }),
                50
              ) || "N/A",
          },
        ]
      case GATSBY_MEDSTAFF_ROLE_ID:
      default:
        return [
          { id: true, type: "string", data: consultation?.consultationId },
          {
            type: "string",
            data: moment(
              consultation?.consultationDateTime?.seconds,
              "dateTime"
            ),
          },
          { type: "string", data: consultation?.patient?.company },
          {
            type: "string",
            data:
              truncateString(
                getPatientName(patientNames, consultation?.userId),
                30
              ) || "N/A",
          },
          { type: "string", data: consultation?.chiefComplaint },
          { type: "string", data: consultation?.nurseAssessment },
          { type: "string", data: consultation?.recommendation },
        ]
    }
  })
}
