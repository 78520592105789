import React, { useState, useEffect } from "react"
import classNames from "classnames"
import Card from "elements/Card"
import Img from "gatsby-image"
import { navigate } from "gatsby"

// import styles from "../utils/search.module.scss"
import { getMHPConsultations } from "../../components/Auth/services/queries/getMHPConsultations.js"
import { getConsultationByType } from "./services/getConsultationByType"

import { useConsultationCardImages } from "./hooks/useConsultationCardImages"

import { getSignedInUser } from "auth/services/user"

import styles from "./utils/card.module.scss"

export const ConsultationCard = ({ type, setView }) => {
  const { userData } = getSignedInUser()
  const [consultations, setConsultations] = useState([])
  const [cardDocumentCount, setCardDocumentCount] = useState(0)
  const [cardLabel, setCardLabel] = useState("")

  let data = useConsultationCardImages()
  const getImage = (type) => {
    switch (type) {
      case "incomplete":
        return data.incomplete.childImageSharp.fixed
      case "submitted":
        return data.submitted?.childImageSharp.fixed
      case "upcoming":
        return data.pendingsubmission?.childImageSharp.fixed
      case "completed":
        return data.confirmed.childImageSharp.fixed
      default:
        return data.incomplete.childImageSharp.fixed
    }
  }

  const getCardStyling = (type) => {
    switch (type) {
      case "incomplete":
        return "__incomplete"
      case "submitted":
        return "__submitted"
      case "upcoming":
        return "__upcoming"
      case "completed":
        return "__completed"
      default:
        return ""
    }
  }

  const getConsultations = async (userData, setConsultations) => {
    await setConsultations(
      await getMHPConsultations({ userEnrollment: userData })
    )
  }

  const setCardData = async (
    consultations,
    setCardDocumentCount,
    setCardLabel
  ) => {
    setCardLabel(type) // "Incomplete consult logs"
    setCardDocumentCount(
      (await consultations?.filter((consultation) =>
        getConsultationByType({ consultation }, type)
      ).length)
    )
  }

  useEffect(() => {
    getConsultations(userData, setConsultations)
    setCardData(consultations, setCardDocumentCount, setCardLabel)
    //eslint-disable-next-line
  }, [getConsultations, setCardData])

  return (
    <Card className={classNames(styles["consultationCard"], styles[getCardStyling(type)])}>
      <div
        aria-hidden="true"
        onClick={() => {
          navigate(`/consultations/mh?status=${type}`)
        }}
        role="button"
        className="is-flex is-flex-direction-row"
      >
        <div className={classNames("mx-1", styles["cardImageContainer"])}>
          <Img loading="eager" fixed={getImage(type)} />
        </div>
        <div className="is-flex is-flex-direction-column">
          <h1 className="mb-0">{cardDocumentCount}</h1>
          <p className="has-text-weight-medium is-size-5">{cardLabel}</p>
        </div>
        
      </div>
    </Card>
  )
}
