import firebase from "firebase"

const getMedicines = async (data) => {
  let newData = []
  await Promise.all(
    data.map(async (doc, index) => {
      let medicineList = []
      let medicineDoc = await doc.medicinesRef.get() //perform this for every document in data
      medicineDoc.forEach((medicine) => {
        medicineList.push(medicine.data())
      })
      delete doc.medicinesRef
      newData.push({ ...doc, medicine: medicineList })
    })
  )

  return newData
}

export const getPatientConsultations = async ({ patientId }) => {
  let patientConsultations = await firebase
    .firestore()
    .collectionGroup("consultations")
    .where("userId", "==", patientId)
    .get()

  let data = []

  patientConsultations.forEach((doc) => {
    let currentConsultation = {
      ...doc.data(),
      id: doc.id,
      medicinesRef: doc.ref.collection("medicines"),
    }
    data.push(currentConsultation)
  })
  data = await getMedicines(data)

  return data
}