export const columns = [
  // {
  //   name: "Email",
  //   options: {
  //     display: false,
  //     filter: true,
  //     setCellHeaderProps: (value) => ({
  //       style: { fontWeight: "bold" },
  //     }),
  //   },
  // },
  {
    name: "Consult Start Date",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Site Name",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Employee Name",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Chief Complaint",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "RN Assessment",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Recommendation",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "",
    options: {
      sort: false,
    },
  },
]
