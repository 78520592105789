import React, { useState, useEffect } from "react"
import classNames from "classnames"

import Datatable from "../Elements/Datatable"
import DashboardLayout from "layout/DashboardLayout"
import { ConsultationCard } from "./ConsultationCard"
import Loading from "elements/Loading"

import { columns } from "./utils/table"
import { columnsMH } from "./utils/tableMH"
import { getTableOptions } from "./services/getTableOptions"
import { getDatatableConsultations } from "../Auth/services/queries/getDatatableConsultations.js"

import {
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
  GATSBY_PSYCHIATRIST_ROLE_ID,
} from "gatsby-env-variables"
import { isBrowser } from "../../services/general"
import { getSignedInUser } from "../Auth/services/user"
import styles from "./utils/card.module.scss"

const getRoleConfig = (role) => {
  switch (role) {
    case GATSBY_MEDSTAFF_ROLE_ID:
      return {
        link: "/consultations/cl/add",
        columns: columns,
        displayConsultationCard: false,
      }
    case GATSBY_MHP_ROLE_ID:
    case GATSBY_PSYCHIATRIST_ROLE_ID:
    default:
      return {
        link: "/consultations/mh/add",
        columns: columnsMH,
        displayConsultationCard: true,
      }
  }
}

const getRoleLinks = (role, config) => {
  switch (role) {
    case GATSBY_MEDSTAFF_ROLE_ID:
    case GATSBY_MHP_ROLE_ID:
    case GATSBY_PSYCHIATRIST_ROLE_ID:
      return []
    default:
      return [
        {
          to: config.link,
          label: "New Consultation",
          color: "primary",
        },
      ]
  }
}

const Consultations = ({ pageContext }) => {
  let { module } = pageContext
  let { userData } = getSignedInUser()
  let roleConfig = getRoleConfig(userData?.role)
  let roleLinks = getRoleLinks(userData?.role, roleConfig)
  const [consultations, setConsultations] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const getStatus = () => {
    if (isBrowser()) {
      let urlParameters = new URLSearchParams(window.location.search)
      if (
        urlParameters?.get("status") === "incomplete" ||
        urlParameters?.get("status") === "submitted" ||
        urlParameters?.get("status") === "completed" ||
        urlParameters?.get("status") === "upcoming"
      )
        return urlParameters?.get("status")
      return null
    }
  }

  const [view, setView] = useState(
    "Consultations" +
      (getStatus() === "incomplete"
        ? " > Incomplete" // " > Incomplete Logs"
        : getStatus() === "submitted"
        ? " > Submitted" // " > Completed Consultation Documents"
        : getStatus() === "completed"
        ? " > Completed"
        : getStatus() === "upcoming"
        ? " > Upcoming"
        : "")
  )

  const getConsultations = async () => {
    setPageLoading(true)
    setConsultations(await getDatatableConsultations({ userData }))
    setPageLoading(false)
  }

  useEffect(() => {
    sessionStorage.setItem("activeConsultations", [])
    getConsultations()

    //eslint-disable-next-line
  }, [])

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={view}
      pageContext={pageContext}
      backPathUrl={view !== "Consultations" ? "/consultations" : null}
      links={roleLinks}
    >
      {roleConfig.displayConsultationCard && !getStatus() && (
        <div
          className={classNames("columns is-gapless", styles["cardContainer"])}
        >
          <div className="column is-three-fifths is-flex flex-direction-row">
            <ConsultationCard type={"upcoming"} setView={setView} />
            <ConsultationCard type={"incomplete"} setView={setView} />
            <ConsultationCard type={"submitted"} setView={setView} />
            <ConsultationCard type={"completed"} setView={setView} />
          </div>
        </div>
      )}

      {pageLoading ? (
        <Loading />
      ) : (
        <Datatable
          data={consultations}
          options={getTableOptions({ pageContext, status: getStatus() })}
          columns={roleConfig.columns}
          defaultSortField={"consultStartDate"}
          defaultSortDirection={"asc"}
        />
      )}
    </DashboardLayout>
  )
}

export default Consultations
