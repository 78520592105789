import { navigate } from "gatsby"

import { getSignedInUser } from "../../Auth/services/user"
import { getPatientConsultations } from "../ConsultationFormPage/services/getPatientConsultations"

import {
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
  GATSBY_PSYCHIATRIST_ROLE_ID,
} from "gatsby-env-variables"

export const getTableOptions = ({ pageContext, status }) => {
  const { userData } = getSignedInUser()

  let statusUrlParameter = pageContext?.module?.name.toLowerCase()

  if (status) statusUrlParameter = `consultations/mh?status=${status}`

  return {
    customToolbarSelect: () => {},
    onRowClick: async (data) => {
      let patientId = data[0]?.data
      let consultId = data[1]?.data

      sessionStorage.setItem(
        "activeConsultations",
        JSON.stringify(await getPatientConsultations({ patientId }))
      )

      switch (userData.role) {
        case GATSBY_MEDSTAFF_ROLE_ID:
          navigate("edit")
          break
        case GATSBY_MHP_ROLE_ID:
        case GATSBY_PSYCHIATRIST_ROLE_ID:
        default:
          navigate(
            `/consultations/mh/add?prevPage=/${statusUrlParameter}&patientId=${patientId}&consultId=${consultId}`
          )
          break
      }
    },
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    customSearch: (searchQuery, currentRow) => {
      let isFound = false
      currentRow.forEach((col) => {
        if (
          col &&
          col.data
            .toString()
            .toLowerCase()
            .replace(/\s/g, "")
            .indexOf(searchQuery.toLowerCase().replace(/\s/g, "")) >= 0
        ) {
          isFound = true
        }
      })
      return isFound
    },
  }
}
